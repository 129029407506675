/* Gotham font */

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Bold.woff2') format('woff2'),
     url('./fonts/Gotham-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'Gotham Book';
  src:url('./fonts/Gotham-Book.woff2') format('woff2'),
     url('./fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Light.woff2') format('woff2'),
     url('./fonts/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-LightItalic.woff2') format('woff2'),
     url('./fonts/Gotham-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src:url('./fonts/Gotham-BookItalic.woff2') format('woff2'),
     url('./fonts/Gotham-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-MediumItalic.woff2') format('woff2'),
     url('./fonts/Gotham-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-BoldItalic.woff2') format('woff2'),
     url('./fonts/Gotham-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Medium.woff2') format('woff2'),
     url('./fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src:url('./fonts/Gotham-Book.woff2') format('woff2'),
     url('./fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-BoldItalic.woff2') format('woff2'),
     url('./fonts/Gotham-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-LightItalic.woff2') format('woff2'),
     url('./fonts/Gotham-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-MediumItalic.woff2') format('woff2'),
     url('./fonts/Gotham-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Bold.woff2') format('woff2'),
     url('./fonts/Gotham-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Light.woff2') format('woff2'),
     url('./fonts/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src:url('./fonts/Gotham-BookItalic.woff2') format('woff2'),
     url('./fonts/Gotham-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Medium.woff2') format('woff2'),
     url('./fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Medium.woff2') format('woff2'),
     url('./fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src:url('./fonts/Gotham-BookItalic.woff2') format('woff2'),
     url('./fonts/Gotham-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-LightItalic.woff2') format('woff2'),
     url('./fonts/Gotham-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src:url('./fonts/Gotham-Book.woff2') format('woff2'),
     url('./fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-MediumItalic.woff2') format('woff2'),
     url('./fonts/Gotham-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Bold.woff2') format('woff2'),
     url('./fonts/Gotham-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-BoldItalic.woff2') format('woff2'),
     url('./fonts/Gotham-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src:url('./fonts/Gotham-Light.woff2') format('woff2'),
     url('./fonts/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Black';
  src:url('./fonts/Gotham-Black.woff2') format('woff2'),
     url('./fonts/Gotham-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Ultra';
  src:url('./fonts/Gotham-Ultra.woff') format('woff');
  font-weight: 800;
  font-style: bold;
  font-display: swap;
}



/* end Gotham font */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* navbar */

nav {
  background-color: #000;
  color: #fff;
  height: 80px;
  line-height: 80px;
}

.logo-nav {
  cursor: pointer;
}

.nav-res {
  background-color: #ED1C24;
  height: 48px;
  font-family: 'Gotham';
  color: #fff;
  font-weight: bold;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  line-height: 40px;
  float: right;
  margin-top: 20px;
  border: none;
}
@media (min-width: 1024px) {
  .nav-res-font{
    font-size: 16px;
    width: 217px;
  }

}

.nav-res-margin{
  margin-right: 35px !important;
}

@media (max-width: 1200px) {
  .nav-res-margin{
    margin-right: 70px !important;
  }
}

.hammen {
  text-align: center;
}

.main-menu-nav-tog {
  cursor: pointer;
  display: inline-block;
  float: right;
  text-align: center;
  width: 80px;
  height: 80px;
  background-color: transparent;
  margin-top: -5px;
}

.main-menu-nav-tog:hover {
  background-color: #343540;
}

.main-menu-nav-tog.show {
  background-color: #343540;
}

.main-menu-nav-tog img {
  margin-top: -2px;
}

.main-menu-nav-tog::after {
  display: none;
}

.main-menu-nav-drop {
  background-color: #343540;
  color: #fff;
  z-index: 10000;
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 52px;
  min-width: 303px;
  border-radius: 0;
  top: -2px !important;
  border: none;
  overflow-y: scroll;
  height: 600px;
}

.main-menu-nav-drop::-webkit-scrollbar {
  width: 0.2em;
}
.main-menu-nav-drop::-webkit-scrollbar-track {
  box-shadow: inset 0 0 15px #343540
}

.main-menu-nav-drop::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.main-menu-nav-drop a {
  text-decoration: none;
  color: #fff;
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 52px;
  padding-left: 20px;
  display: inline-block;
  height: 52px;
  float: left;
  width: 100%;
}

.main-menu-nav-drop a:hover {
  font-weight: bold;
}
.main-menu-nav-drop li {
  cursor: pointer;
  padding-left: 20px;
}

/* navbar end */

/* calendar popup */

.react-responsive-modal-modal {
  padding: 34px !important;
  min-width: 63%;
}
.react-responsive-modal-closeButton {
  top: 20px !important;
  right: 20px !important;
}
.innerwraper {

}

.innerwraper form {
  max-width: 416px;
  margin-left: auto;
  margin-right: auto;
}

.pop-intro-text {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-family: 'Gotham Book';
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 25px;
  color: #000;
}

.innerpick-pop {
  display: inline-block;
  position: relative;
  width: 49%;
  float: right;
}

.innerpick-pop:first-of-type {
  float: left;
}

.innerinput {
  height: 50px;
  border: solid 1px #BFBFBF;
  border-radius: 10px;
  padding-left: 10px;
  width: 99%;
  margin-top: 10px;
}


.innerpick-pop:last-of-type {
  float: right;
}

.databadania-pop {
  height: 50px;
  border: solid 1px #BFBFBF;
  border-radius: 10px;
  padding-left: 10px;
  width: 100%;
}

.czasbadania-pop {
  height: 50px;
  border: solid 1px #BFBFBF;
  border-radius: 10px;
  padding-left: 10px;
  width: 100%;
}

.innerchecklabel {
  font-family: 'Gotham';
  font-weight: 300;
  color: #000;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  float: right;
  cursor: pointer;
  width: 96%;
}

.innercheck {
  cursor: pointer;
  float: left;
  margin-top: 14px;
}

.checkwrapper1 {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.checkwrapper2 {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.innersubmit {
  width: 100%;
  font-family: 'Gotham';
  font-weight: 500;
  color: #fff;
  background-color: #ED1C24;
  line-height: 24px;
  font-size: 16px;
  height: 46px;
  border: solid 2px #ED1C24;
  border-radius: 7px;
  margin-top: 10px;
  transition: all 0.2s;
}

.innersubmit:hover {
  color: #000;
  background-color: transparent;
}

.aftermail-title {
  font-family: 'Gotham Book';
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 25px;
}

.aftermail-title img {
  margin-right: 21px;
  height: 28px;
}

.aftermail-text {
  font-family: 'Gotham Book';
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 24px;
}
.aftermail-text span{
  font-family: 'Gotham';
  font-weight: bold;
}
.aftermail-dtbox {
  display: inline-block;
  width: 50%;
  margin-left: 25%;
  margin-top: 24px;
  margin-bottom: 48px;
}

.aftermail-dtbox-item {
  display: inline-block;
  width: 50%;
}

.aftermail-close-box {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.aftermail-close-box button {
  background-color: #fff;
  border: solid 1px #000;
  width: 60%;
  font-size: 18px;
  font-family: 'Gotham';
  font-weight: medium;
  line-height: 50px;
  border-radius: 30px;
  margin-top: 48px;
}

@media (max-width: 576px) {
  .innerchecklabel {
    width: 90%;
  }
}

/* calendar popup end */


/* home page */

.black-section {
  background-color: #000;
  color: #fff;
}
.homepage-welcome-screen {
  padding-bottom: 40px;
  padding-top: 80px;
}

.homepage-welcome-screen h1{
  font-family: 'Gotham Black';
  font-size: 70px;
  text-align: center;
}

.cat-carousel {
  margin-top: 20px;
}

.cat-carousel-cards {
  margin-top: 40px;
}
.cat-carousel-card {
  text-align: center;
  margin-bottom: 25px;
  transition: all 3s linear;
}


.cat-carousel-card-inner {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  height: 260px;
  border-radius: 7px;
  overflow: hidden;
}
.cat-carousel-card-img {
 height: 190px;
 overflow: hidden;
}

.cat-carousel-card-img img {
    height: 100%;
    margin-left: -15%;
}

.cat-carousel-card-title {
  display: inline-block;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #FFFFFF;
  background-color: #111519;
  width: 100%;
}

.cat-carousel-card-inner:hover .cat-carousel-card-title {
  background-color: #343540;
}

.homepage-welcome-screen .cat-carousel-tags {
  text-align: center;
}

.homepage-welcome-screen .cat-carousel-tags button {
  font-family: 'Gotham Book';
  font-size: 18px;
  height: 48px;
  text-align: center;
  background-color: transparent;
  color: #fff;
  border: 1px solid #343540;
  border-radius: 7px;
  margin-left: 7px;
  margin-right: 7px;
}

.homepage-welcome-screen .cat-carousel-tags button.active {
  background-color: #343540;
}

.homepage-welcome-screen .res-box {
  text-align: center;
  margin-top: 27px;
  margin-bottom: 24px;
}
.homepage-welcome-screen .res-box button {
  background-color: #ED1C24;
  display: inline-block;
  height: 48px;
  font-family: 'Gotham';
  color: #fff;
  font-weight: bold;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  border: none;
}

.addsection {
  background-color: #F5F8FA;
  padding-top: 40px;
}

.ad-video {
  position: relative;
  cursor: pointer;
}

.ad-video-onvideo-text {
  position: absolute;
  padding-left: 15px;
  top: 0;
  left: 0;
}

.ad-video-tag {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 34px;
  color: #fff;
  background-color: #000;
  border-radius: 7px;
  margin-top: 17px;
  margin-left: 20px;
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.ad-video-title {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: #fff;
  margin-left: 20px;
  margin-top: 7px;
}

.ad-video video {
  max-width: 100%;
  border-radius: 7px;
}


.ad-video-mobile video {
  max-width: 100%;
  border-radius: 7px;
  display: none;
}

.ads-card-container {
  margin-top: 50px;
  margin-bottom: 42px;
  border-radius: 7px;
  overflow: hidden;
}

.ad-card-inner {
  background-color: #fff;
    border-radius: 7px;
    overflow: hidden;
    height: 100%;
    padding-bottom: 17px;
    cursor: pointer;
}
.ad-card-text-part {
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 20px;
  padding-bottom: 17px;
}

.ad-cart-img-part {
  position: relative;
}

.ad-card-title-tag {
  position: absolute;
  color: #fff;
  background-color: #000;
  top: 13px;
  left: 13px;
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 24px;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 5px;
}

.ad-cart-img-part img {
  width: 100%;
}

.ad-card-icon-part img {
  width: 46px;
  height: 46px;
}

.ad-card-icon-part {
  width: 46px;
  float: left;
  margin-right: 34px;
}
.ad-card-subtitle-part {
  width: calc(100% - 80px);
  float: left;
}

.ad-card-subtitle {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.ad-card-price {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 26px;
}

.ad-card-price-stroke {
  text-decoration: line-through;
}

.ad-card-price-promo {
  color: #ED1C24;
}

.res-box-bottom {
  text-align: center;
  padding-bottom: 60px;
  padding-top: 12px;
  margin-top: 38px;
}

.res-box-bottom button {
  background-color: #ED1C24;
  display: inline-block;
  height: 48px;
  font-family: 'Gotham';
  color: #fff;
  font-weight: bold;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  border: none;
}

.ad-video-mobile {
  position: relative;
  cursor: pointer;
}

.ad-video-onvideo-text-mobile {
  display: none;
}

.mob-cat-box {
  display: none;
}

.res-box-mobileonly {
  display: none;
  text-align: center;
  margin-bottom: 47px;
  margin-top: 24px;
}

.res-box-mobileonly button {
  background-color: #ED1C24;
  display: inline-block;
  height: 48px;
  font-family: 'Gotham';
  color: #fff;
  font-weight: bold;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  border: none;
}

@media (max-width: 576px) {
  .res-box-mobileonly {
    display: initial;
  }
  .cat-box, .dontshowmeonmobile {
    display: none;
  }
  .cat-carousel-card-img img {
    height: auto;
    width: 100%;
    margin-left: 0;
  }

  .cat-carousel-card-img-szkolenie img {
    height: auto;
    width: 100%;
    margin-left: 0;
  }

  .homepage-welcome-screen h1 {
    font-family: 'Gotham Black';
    font-size: 57px;
    text-align: center;
    margin-top: 59px;
    margin-bottom: 32px;
  }

  .nav-res {
    display: none;
  }
  .ad-video-mobile, .ad-video-mobile video {
    display: inline-block;
  }
  .ad-video {
    display: none;
  }

  .res-box-bottom {
    display: none;
  }
  .ads-card-container {
    margin-top: 20px;
    margin-bottom: 0;
  }
  .addsection {
    padding-bottom: 56px;
    padding-top: 20px;
  }

  .ads-card-container:first-child {
    margin-top: 15px;
  }

  .ad-video-onvideo-text-mobile {
    display: initial;
    position: absolute;
    padding-left: 15px;
    top: 0;
    left: 0;
  }

  .mob-cat-box {
    display: initial;
    width: auto;
    overflow-y: scroll;
  }

  .mob-cat-carousel-card {
    /* float: left; */
    max-width: 220px;
  }
  .cat-car-mob-wrapper {
    width: 1650px;
  }
  .mob-cat-carousel-card-inner {}

  .mob-cat-carousel-card-img {
    height: 180px;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .mob-cat-carousel-card-img img {
    height: 180px;
    width: 100%;
    object-fit: cover;
  }
  .mob-cat-carousel-card-title {
    font-family: 'Gotham Black';
    text-align: center;
    background-color: #111519;
    display: flex;
    width: 100%;
    margin-top: -5px;
    height: 70px;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .activebox .mob-cat-carousel-card-title {
      background-color: #343540;
  }

  .mob-cat-carousel-card-img-szkolenie {
    height: 180px;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .mob-cat-carousel-card-img-szkolenie img {
    height: 180px;
    width: 100%;
    object-fit: cover;
  }
  .mob-cat-carousel-card-title-szkolenie {
    font-family: 'Gotham Black';
    text-align: center;
    background-color: #343540;
    display: flex;
    width: 100%;
    margin-top: -5px;
    height: 70px;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

}

/* home page end */

/* detail page  */

.service-top-hero {
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  z-index: 1000;
  /* position: relative; */
}

.service-top-hero-tag {
  margin-top: 25px;
}
.service-top-hero-tag p {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  width: auto;
  display: inline-block;
  background-color: #000;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 5px;
  border-radius: 7px;
}

.service-top-hero-bottom-part {
  position: absolute;
    width: 100%;
    bottom: 0;
    padding-top: 150px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 95%);
}

.service-top-hero-title {
  color: #fff;
}

.parallax-bg-services {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.service-top-hero-title h1 {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 52px;
  width: auto;
  float: left;
}

.service-top-hero-black-bottom-subtitle {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 13px;
}

.service-top-hero-black-bottom-price {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  
  margin-bottom: 35px;
}

.service-top-hero-black-bottom {
  color: #fff;
}

.service-main-content {
  background-color: #F5F8FA;
  padding-top: 64px;
  margin-top: -10px;
  padding-bottom: 67px;
}

.service-desc-par {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
}

.services-packages-row {
  margin-top: 60px;
}

.service-media-part img {
  width: 100%;
  border-radius: 7px;
  margin-bottom: 16px;
}

.service-media-part-single video {
  max-width: 100%;
  border-radius: 7px;
}

.service-media-part-single {
  position: relative;
}
.service-media-part-single-text {
  position: absolute;
  top: 13px;
  left: 13px;
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 24px;
  background-color: #fff;
  color: #000;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.package-single {
  border-radius: 7px;
  background-color: #fff;
  padding: 22px;
  position: relative;
}

.package-single-mostpoppular {
  /* width: 100%;
  display: inline-block;
  margin-top: -22px;
  margin-left: -22px;
  margin-bottom: 21px; */
  position: absolute;
  right: 0;
  top: 0;
}

.package-single-mostpoppular p {
  background-color: #ED1C24;
  width: auto;
  float: left;
  font-family: 'Gotham Book';
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.package-single-name {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  margin-top: 14px;
}

.package-single-features {
  margin-top: 26px;
}

.package-single-features-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 26px;
}

.package-single-features-item-icon {
  width: 22px;
  float: left;
  margin-right: 22px;
  height: 22px;
}
.package-single-features-item-name {
  font-family: 'Gotham Book';
  width: calc(100% - 44px);
  font-size: 14px;
  line-height: 20px;
  float: left;
}

.packge-single-aditional-info {
  width: 100%;
  display: inline-block;
}
.package-single-time {
  width: 50%;
  float: left;
}
.package-single-time p {
  font-family: 'Gotham Book';
  font-size: 14px;
  line-height: 20px;
}
.package-single-price {
  width: 50%;
  float: right;
  text-align: right;
}
.package-single-price p {
  font-family: 'Gotham';
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
}

.nav-res-service {
  background-color: #ED1C24;
  height: 48px;
  font-family: 'Gotham';
  color: #fff;
  font-weight: bold;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  line-height: 40px;
  float: right;
  margin-top: 20px;
  border: none;
}
.showmeonlyonmobile {
  display: none;
}

.service-top-mob-left {
  display: inline-block;
  float: left;
  width: 62%;
  padding-left: 14px;
}
.service-top-mob-right {
  padding-right: 14px;
  width: 33%;
  /* height: 100%; */
  float: right;
  display: flex;
  justify-content: right;
  align-items: center;
}
.service-top-mob-left-otomoto {
  display: inline-block;
  float: left;
  /* width: 62%; */
  padding-left: 14px;
}
.service-top-mob-right-otomoto {
  padding-right: 14px;
  /* width: 33%; */
  /* height: 100%; */
  float: right;
  display: flex;
  justify-content: right;
  align-items: center;
}
@media (max-width: 576px) {
  .showmeonlyonmobile {
    display: initial;
  }
  .parallax-bg-services {
    width: auto !important;
    height: 100% !important;
  }

  .package-single {
    margin-bottom: 30px;
  }
  .service-top-hero-title h1 {
      margin-bottom: 49px;
  }
  .nav-res-service {
    margin-top: 0px;
  }

}

/* detail page end */

/* footer */

.footer {
    background-color: #000;
    color: #fff;
    padding-top: 40px;
    padding-bottom: 44px;
}

.footer-icon {
  float: left;
}

.footer-phone, .footer-mail {
  display: inline-block;
  width: 100%;
}

.footer-phone img {
  margin-top: 3px;
  margin-right: 16px;
}

.footer-mail img {
  margin-top: 7px;
  margin-right: 16px;
}

.footer-active-link {
  text-decoration: none !important;
  color: #fff;
  cursor: pointer;
}

.footer h4 {
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: bold;
}

.footer p, .footer table {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 26px;
}



.footer table td{
  padding-right: 21px;
}

@media (max-width: 576px) {
  .footer .col-lg-4 {
    margin-bottom: 32px;
  }

  .footer {
    padding-bottom: 0px;
  }
}


/* end footer */


/* mobile menu */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 20px;
  height: 14px;
  right: 36px;
  top: 36px;
  display: none;
}

@media (max-width: 576px) {
  .bm-burger-button {
    display: initial;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
  height: 2px !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #343540;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu a {
  color: #fff;
  text-decoration: none;
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 18px;
  line-height: 39px;
  cursor: pointer;
}

.bm-menu nav {
  background-color: #343540 !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #343540;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


/* service skp ads */

.skpad-container-desktop {
  background-color: #000;
  display: inline-block;
  width: 100%;
  padding-bottom: 48px;
  padding-top: 13px;
}

.skpad-desktop-image {
  max-width: 100%;
}

.skpad-container-mobile {
  display: none;
}

.skpad-top-margin-fix {
  margin-top: 9px;
}

@media (max-width: 991px) {
  .skpad-container-desktop {
    display: none;
  }

  .skpad-container-mobile {
    background-color: #000;
    display: inline-block;
    width: 100%;
    padding-bottom: 48px;
  }
  .react-responsive-modal-modal {
    max-width: 100% !important;
}
}

/* opinie */
.opinie-single-name {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  margin-top: -9px;
}

@media (max-width: 767px) {
  .opinie-single-name {
    font-family: 'Gotham';
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    margin-top: -3px;
  }
  .opinie-single-features-item-name {
    font-family: 'Gotham Book';
    width: calc(100% - 44px);
    font-size: 11px;
    line-height: 20px;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
  }
}



.cat-carousel-card-szkolenie {
  text-align: center;
  margin-bottom: 25px;
  transition: all 3s linear;
}


.cat-carousel-card-inner-szkolenie {
  width: 100%;
  display: inline-block;
  cursor: pointer;
  height: 260px;
  border-radius: 7px;
  /* overflow: hidden; */
}
.cat-carousel-card-img-szkolenie {
 height: 100%;
 overflow: hidden;
 border-radius: 7px 7px 0px 0px;
}


.cat-carousel-card-img-szkolenie img {
    height: 100%;
    margin-left: -15%;
}

.cat-carousel-card-title-szkolenie {
  display: inline-block;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #FFFFFF;
  background-color: #111519;
  width: 100%;
  border-radius: 0px 0px 7px 7px;
}

.activebox .cat-carousel-card-title-szkolenie {
  background-color: #343540;
}

.cat-carousel-card-szkolenie:hover .cat-carousel-card-title-szkolenie {
  background-color: #343540;
}

.service-top-hero-uro-master {
  background-color: #F5F8FA;
  padding-top: 30px;
}

.service-top-hero-uro p {
  width: 100%;
  color: #ed1522;
  font-family: 'Gotham Ultra';
  text-transform: uppercase;
  font-size: 7em;
  line-height: 0.9em;
  position: sticky;
  z-index: 1;
  background-color: transparent;
  padding-left: 0;
}

.cieniekuro {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}

.urouro {
  max-width: 100%;
}

.conturo {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.urotext {
  float: left;
  width: 23%;
}

@media (max-width: 767px) {
  .service-top-hero-uro p {
    font-size: 14vw;
    line-height: 13vw;
  }
  .urotext {
    position: absolute;
    right: 5px;
    top: 20vw;
    width: 38vw;
  }

}

.luromaincontent {
  padding-top: 20px;
}

.luromaintitle {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 33px;
  margin-top: 30px;
}

.luro_data {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 25px;
  color: #8D8FA6;
  line-height: 27px;
}

.luro_title {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 20px;
}

.luro_desc {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 10px;
}
.luro_red_data {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 27px;
  color: #ED1C24;
}

.luroprezdesk {
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 27px;
}
.lurozapis a {
  width: 100% !important;
  /* margin-bottom: 27px; */
  margin-top: 20px;
}

.lurohidemoondeskt {
  display: none;
}
.luroprezimg {
  max-width: 100%;
}

.luropreztit {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 25px;
}

.luromorebottomspace {
  margin-bottom: 90px;
}

.luropreztextcont {
  display: flex;
  align-content: space-around;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 25px;
}

.luroprezimgcont {
  text-align: center;
}

.uroaddesk {
  border-radius: 7px;
  cursor: pointer;
  width: 98%;
  height: 100%;
  border: solid 1px #D7D8E0;
  padding: 0;
  margin-left: 1%;
  margin-right: 1%;
}

.uroadmob {
  display: none;
  width: 94%;
  border-radius: 7px;
  cursor: pointer;
  height: auto;
  border: solid 1px #D7D8E0;
  padding: 0;
  margin-left: 3%;
  margin-right: 3%;
}

@media (max-width: 500px) { 
  .lurohidemoonmobile {
    display: none;
  }
  .uroadmob {
    display: initial;
  }
  .uroaddesk {
    display: none;
  }
  .luromaincontent {
    padding-top: 57px;
  }
  .lurohidemoondeskt {
    display: flex;
  }
  .luromorebottomspace {
    margin-bottom: 30px;
  }
  .service-top-hero-uro-master {
    height: 60vh;
    padding-top: 0px;
}
.luromaintitle {
  margin-top: 0px;
}
.luropreztit {
  line-height: 27px;
  margin-bottom: 15px;
}
.luroprezimg {
  margin-bottom: 20px;
}
}

.topphonefix {
  float: right;
  padding-top: 3px;
  font-weight: bold;
  margin-right: 10px;
}

.luromorebottomspace div {
  margin-bottom: 30px;
}

.lilsherfix {
  width: 70%;
}

.lilsherfix2 {
  font-size: 0.8em;
  text-align: right;
}

.homepage-welcome-screen-fix {
  padding-top: 0px;
}