.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* time & date picker */
/* time */

.react-datepicker__triangle, .react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only {
  display: none !important;
}

.react-datepicker-popper {
  padding-top: 0px !important;
  -webkit-box-shadow: 0px 0px 28px -6px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 28px -6px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 28px -6px rgba(66, 68, 90, 1);
  border-radius: 7px;
  overflow: hidden;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .react-datepicker__time {
    scrollbar-width: auto;
    scrollbar-color: #e0e0e0 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .react-datepicker__time::-webkit-scrollbar {
    width: 15px;
  }

  .react-datepicker__time::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .react-datepicker__time::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

.react-datepicker.react-datepicker--time-only {
  width: 100%;
}
.react-datepicker__header {
  background-color: #fff !important;
  border-bottom-color: transparent !important;
}

.innerpick-pop {
  cursor: pointer;
}

.czasbadania-pop, .databadania-pop {
  cursor: pointer;
}

.innertimepop .react-datepicker-popper {
  width: 100%;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  width: 100%;
}

.react-datepicker__time-container, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100% !important;
  text-align: left !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  font-family: 'Gotham Book';
  font-size: 14px;
  margin-bottom: 10px;
}

.react-datepicker {
  border: none !important;
  max-width: 100%;
}

.react-datepicker__month-container {
  max-width: 100%;
}

.menu-item {
  cursor: pointer;
}
/* 
.menu-item-bold {
  font: normal normal bold 18px/52px Gotham;
} */


/* price list table */

.pricelist-table {
  width: 100%;
  text-align: left;
  margin-top: 36px;
}

.pricelist-table2 {
  margin-top: 0px;
}

.pricelist-table td {
  min-width: 90px;
  text-align: center;
}

.pricelist-table td:first-child {
  padding-left: 27px;
  padding-right: 20px;
  text-align: left;
}

.pricelist-table thead {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 16px;
  line-height: 38px;
  background-color: #E8E8E8;
}

.pricelist-table tbody {
  font-family: 'Gotham Book';
  font-weight: normal;
  font-size: 16px;
  line-height: 38px;
}

.pricelist-table tbody tr td:last-child {
  width: 156px;
  text-align: center;
}

.pricelist-disclaimer {
  font-family: 'Gotham Book';
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 30px;
  text-align: center;
}

/* folie table */

.pakiety-font{
  padding-top: 54px;
  font-size: 28px;
  font-family: 'Gotham Book';
  font-weight: bold;
}

.folia-table {
  width: 100%;
  text-align: left;
  margin-top: 36px;
}

.folia-table2 {
  margin-top: 0px;
}

.folia-table td {
  min-width: 90px;
  text-align: center;
}

.folia-table td {
  padding-left: 27px;
  padding-right: 20px;
  font-weight: normal;
  text-align: left;
  color: #ffffff;
}

.folia-table-tbody td{
  color: #000000;
  vertical-align: top;
  padding-top: 25px;
  padding-bottom: 25px;
}

.folia-table thead {
  font-family: 'Gotham';
  font-weight: normal;
  font-size: 16px;
  line-height: 38px;
  background-color: #000000;
}

.folia-table tbody {
  font-family: 'Gotham Book';
  font-weight: normal;
  color: #000000;
  font-size: 12px;
  /* line-height: 38px; */
}

.folia-table tbody tr td:last-child {
  width: 900px;
  text-align: left;
}

.folia-table tr{
  border-top: 0.5px solid rgb(210, 210, 210);
}
@media (max-width: 767px) {
  .folia-table tbody tr td:last-child {
    width: 800px;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .folia-table thead td:first-child {
    padding-left: 10px;
    width: 40%;
    text-align: left;
  }

  .folia-table td {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: normal;
    text-align: left;
  }
  .folia-table thead {
    font-family: 'Gotham';
    font-weight: normal;
    font-size: 14px;
    line-height: 38px;
    background-color: #000000;
  }
  .folia-table p{
    font-size: 11px;
  }
}
.folia-disclaimer {
  font-family: 'Gotham Book';
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 30px;
  text-align: center;
}

.package-image {
  width: 100%;
  /* height: 249px; */
  padding-right: 5px;
  padding-left: 5px;
}

.package-container{
  flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
/* temp szkolenie */
.michtext {
  font-size: 18px;
  font-family: 'Gotham Book';
  font-weight: normal;
}
.black-version {
  background-color: #000;
  color: #fff;
}

.nb {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 18px;
}

.pst {
  font-weight: 300;
  text-decoration: line-through;
}

.pr {
  color:#FF0000;
}
.tszctap {
  width: auto;
  margin-top: 10px;
  font-size: 1.2em;
}
.tszcta {
  width: auto;
}
.szdescpar b {
  font-family: 'Gotham';
  font-weight: bold;
}

.openszko {
  cursor: pointer;
}
.openszko:hover {
  color:#FF0000;
  text-decoration: underline;
}

.readmoreclick {
  color: #FF0000;
  cursor: pointer;
}

.slideItem {
  width: 443px;
  height: 249px;
  padding-right: 5px;
  padding-left: 5px;
}
.carousel-row {
  margin-top: 40px;
  position: relative;
}
.slideForBut {
  position: absolute;
    right: 20px;
    z-index: 10;
}
.slideBacBut {
  position: absolute;
    left: 20px;
    z-index: 10;
}

.pop-center-text {
  text-align: center;
}

.pop-center-text span {
  margin-right: 5px;
  margin-left: 5px;
}
/* temp szkolenie end */

.aditionalszkoleniebutton {
  display: inline-block;
    text-align: center;
    width: 100%;
}

.aditionalszkoleniebuttonbut {
  float: initial;
  margin-top: 40px;
}

.nav {
  position: fixed;
  z-index: 10000;
  width: 100%;
}

.react-parallax {
  padding-top: 80px;
}


.pllogin {
  min-height: 800px;
  width: 50%;
  margin-left: 25%;
}

.plloginbox {
  /* background-color: #fff; */
  border-radius: 8px;
  text-align: center;
}

.pllogin {
  min-height: 800px;
  width: 50%;
  margin-left: 25%;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
}
.lsbut a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ED1C24;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  min-width: 300px;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  font-family: 'Gotham';
  color: #fff;
  font-weight: bold;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  border: none;
}
.lsbutreg {}
.lsbutlog {}
.lsmidle {
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Gotham Book';
    font-size: 18px;
    line-height: 24px;
}

@media (max-width: 767px) {
  .pllogin {
    width: 100%;
    margin-left: 0;
  }
}

.package-single-desc {
  padding-top: 26px;
  display: inline-block;
}

.package-single-desc p {
  font-family: 'Gotham Book';
  font-size: 14px;
  line-height: 18px;
  float: left;
}

.react-responsive-modal-root {
  z-index: 10000 !important;
}

.mapakontakt-con {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  height: 50vh;
  margin-bottom: 60px;
  margin-top: 50px;
}

.mapakontakt {
  width: 100%;
  height: 100%;
}

.contactpage {
  padding-top: 90px;
}

.danekontakt-con {
  margin-top: 90px;
}

.socialFooter {
  margin-top: 20px;
}

.socialFooterItem {
  margin-right: 25px;
  display: inline-block;
}

.social-footer-icon {

}

.contact-active-link, .contact-social-link {
  display: inline-block;
  padding-top: 10px;
  text-decoration: none;
  color: #000;
}

.clickme {
  cursor: pointer;
}

.promopageback {
  background-color: #000;
  color: #fff;
}

.promocont {
  padding-top: 100px;
}

.promolist {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

.promotitle {
  font-family: 'Gotham';
  font-weight: bold;
  font-size: 28px;
}

.promolist {
  margin-top: 62px;
}

.promo-con {

}

.promoimg {
  max-width: 100%;
  border-radius: 15px;
  border: solid 1px #3e3e3e;
}

.promodesc {
  margin-top: 50px;
  margin-bottom: 80px;
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 26px;
}
.promodesc a {
  color: #FF0000;
} 

.ltfix {
  line-height: 30px;
}

.cat-carousel-card-img {
  position: relative;
}

.ybadge {
  font-family: 'Gotham Book';
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  right: 0;
  color: #ED1C24;
  background-color: #EDD51C;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
}
.ybadge-szkolenie {
  font-family: 'Gotham Book';
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  /* right: 0; */
  color: #ED1C24;
  background-color: #EDD51C;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
}

.servicetitleholder {
  position: relative;
}

.ybadge2 {
  font-family: 'Gotham Book';
  font-size: 16px;
  line-height: 24px;
  /* position: absolute; */
  right: 0;
  color: #ED1C24;
  background-color: #EDD51C;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  width: auto;
  float: left;
  display: inline-block;
  margin-top: 5px;
  margin-left: 13px;
}

.rpack {
  color: #ED1C24;
  font-weight: 600;
}

.disclaimerrow {
  margin-top: 46px;
}

.disctext {
  font-family: 'Gotham Book';
  font-size: 14px;
  line-height: 20px;
}

.disctext a {
  color: #FF0000;
}

.opopnytextreg {
  text-align: center;
  font-size: 20px;
  line-height: 35px;
}

.package-single-folia{
  height: 400px;
    border-radius: 7px;
    background-color: #fff;
    padding: 22px;
    position: relative;
}

@media (max-width: 767px) {
  .package-single-folia{
    height: 400px;
    margin-top: 50px;
      border-radius: 7px;
      background-color: #fff;
      padding: 22px;
      position: relative;
  }
}

/* opinie */
.avatar-image {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
}

.gwiazda-image {
  width: 8%;
  height: 8%;
  margin-left: 5px;
  padding-right: 1px;
  padding-left: 1px;
}

.gwiazda-pos {
  padding-left: 5px;
}

.col-lg-3-opinie{
  flex-shrink: 0;
  width: 20%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}
.col-lg-8-opinie{
  flex-shrink: 0;
  width: 80%;
  max-width: 100%;
  margin-left: -10px;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.slideForButOpinie {
  position: absolute;
    right: -15px;
    z-index: 10;
}
.slideBacButOpinie {
  position: absolute;
    left: -22px;
    z-index: 10;
}
.slideItemOpinie {
  min-width: 437px;
  height: 259px;
  padding-right: 5px;
  padding-left: 5px;
}
.package-single-opinie{
  height: 400px;
  border-radius: 7px;
  background-color: #fff;
  padding: 22px;
  position: relative;
}
.slideItemOtomoto {
  max-width: 325px;
  height: 290px;
  padding-right: 5px;
  padding-left: 5px;
}
.slideItemOtomotoList {
  max-width: 215px;
  height: auto;
  padding-right: 5px;
  padding-left: 5px;
}
.slideForButOtomoto {
  position: absolute;
    right: -1%;
    z-index: 10;
}
.slideBacButOtomoto {
  position: absolute;
    left: -1.5%;
    z-index: 10;
}
.slideForButOtomotoSmall {
  position: absolute;
    right: -3%;
    z-index: 10;
}
.slideBacButOtomotoSmall {
  position: absolute;
    left: -4.5%;
    z-index: 10;
}
@media (max-width: 767px) {
  .slideItemOpinie {
    width: 443px;
    height: 279px;
    padding-right: 30px;
    padding-left: 5px;
  }
  
  .slideForButOpinie {
    position: absolute;
    top: 51%;
      right: 10px;
      z-index: 10;
  }
  .slideBacButOpinie {
    position: absolute;
    top: 50%;
      left: 5px;
      z-index: 10;
  }
  .avatar-image {
    width: 100%;
    /* height: 249px; */
    padding-top: 0px;
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .gwiazda-image {
    width: 6%;
    height: 6%;
    /* height: 249px; */
    margin-left: 5px;
    padding-right: 1px;
    padding-left: 1px;
  }
  
  .gwiazda-pos {
    padding-left: 5px;
  }


  .col-lg-3-opinie{
    flex-shrink: 0;
    width: 20%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
  .col-lg-8-opinie{
    flex-shrink: 0;
    width: 80%;
    max-width: 100%;
    margin-left: -10px;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
  .package-single-opinie{
    height: 400px;
    width: 100%;
    margin-top: 50px;
      border-radius: 7px;
      background-color: #fff;
      padding: 22px;
      position: relative;
  }
  .slideItemOtomoto {
    width: 443px;
    height: 279px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .slideForButOtomoto {
    position: absolute;
      right: 15px;
      z-index: 10;
  }
  .slideBacButOtomoto {
    position: absolute;
      left: 5px;
      z-index: 10;
  }
  .slideForButOtomotoSmall {
    position: absolute;
      right: 2%;
      z-index: 10;
  }
  .slideBacButOtomotoSmall {
    position: absolute;
      left: -1%;
      z-index: 10;
  }
}


hr.center-ball {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(237 28 36), rgba(0, 0, 0, 0));
  background-color: transparent;
}

.mobphonefix1 {
  margin-bottom: 30px;
}

.mobphonefix2 {
  margin-top: 0px;
  font-weight: bold;
}

.brakmiejsc {
  text-decoration: line-through;
  opacity: 0.5;
}

.ak-mob {
  display: none; 
}

.ak-img-hp {
  max-width: 100%;
  border-radius: 11px;
}

@media (max-width: 580px) {
  .ak-mob {
    display: initial;
  }
  .ak-desk {
    display: none;
  }
}


.otomoto-img {
  max-width: 100%;
  border-radius: 10px;
}
.cat-carousel-card-inner-otomoto-list {
  border-radius: 7px;
  overflow: hidden;
}
.cat-carousel-card-inner-otomoto {
  height: 290px;
  border-radius: 0px;
  overflow: hidden;
}

.text-left{
  text-align: left;
}

.collapse {
  transition: height 0.5s ease;
  height: 0;
  overflow: hidden;
}

.collapse.show {
  height: auto;
}


.resbotpt {
  display: inline-block;
}